html,
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::selection {
  background: hsla(252, 17%, 54%, 0.52);
}

.notistack-SnackbarContainer {
  width: auto;
  top: 72px !important;
}

.notistack-Snackbar,
.notistack-Snackbar > div {
  min-width: auto !important;
}

html {
  height: 100%;
  max-width: 100vw;
}

body {
  height: 100%;
  max-width: 100vw;
  background: #110c20 !important;
  min-width: 330px !important;
}

.nonSelect * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900&display=swap');

@font-face {
  font-family: 'Iranyekan Bold';
  src: local('AirbnbCerealLight'), url('@fonts/Qs_Iranyekan\ extrabold.ttf');
  /* Add additional font formats here for cross-browser support */
}

@font-face {
  font-family: 'Iranyekan';
  src: local('AirbnbCerealLight'), url('@fonts/Qs_Iranyekan.ttf');

  /* Add additional font formats here for cross-browser support */
}

@font-face {
  font-family: 'Iranyekan Light';
  src: local('AirbnbCerealLight'), url('@fonts/Qs_Iranyekan\ light.ttf');

  /* Add additional font formats here for cross-browser support */
}
@font-face {
  font-family: 'Iranyekan Thin';
  src: local('AirbnbCerealLight'), url('@fonts/Qs_Iranyekan\ thin.ttf');

  /* Add additional font formats here for cross-browser support */
}

#root {
  height: 100%;
}

iframe {
  width: 100%;
  border: none;
  flex: 1;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.gbtn {
  background: transparent !important;
  border-radius: 12px !important;
  box-shadow: none !important;
}

/* Track */
*::-webkit-scrollbar-track {
  /* border-radius: 5px; */
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: transparent;
  /* border-radius: 5px 0 0 5px; */
  /* box-shadow: inset 5px -5px 5px rgba(58, 114, 60, 1); */
}

::-webkit-scrollbar {
  display: none;
}

.disabled-link {
  pointer-events: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
}

#g_a11y_announcement {
  left: 0 !important;
}

body .crisp-client .cc-tlyw .cc-nsge,
.crisp-client .cc-tlyw .cc-nsge :after,
.crisp-client .cc-tlyw .cc-nsge :before {
  visibility: hidden !important;
  display: none !important;
}

body .crisp-client .cc-tlyw .cc-kxkl .cc-nsge {
  display: none !important;
}

body .crisp-client .cc-tlyw[data-full-view='false'] .cc-kxkl .cc-nsge,
.crisp-client
  .cc-tlyw[data-full-view='false']
  .cc-kxkl
  .cc-1hqb[data-visible='true'] {
  margin-bottom: -12px !important;
}

